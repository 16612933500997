/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Plate from '../../../components/Plate';
import FleetButton from '../../../components/FleetButton';
import FleetTooltip from '../../../components/FleetTooltip';
import { useDevice } from '../../../hooks/device';
import { useRepository } from '../../../hooks/api';
import { useCompany } from '../../../hooks/company';
import { useSnackbar } from '../../../hooks/snackbar';
import { lastRowWithoutBottomBorder } from '../../../shared/styles';
import {
  getLicensePlateLabel,
  getShortVinNumber,
} from '../../../shared/labels';

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  ...lastRowWithoutBottomBorder,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function VehicleRow({
  vehicle,
  onVehiclesRefresh,
  onVehicleEdit,
}) {
  const company = useCompany();
  const repository = useRepository();
  const openSnackbar = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { isBrowser } = useDevice();

  const handleVehicleDelete = async () => {
    const { status } = await repository.company.deleteVehicle(
      company.taxIdentificationHash,
      vehicle.id
    );

    setDeleteModalOpen(false);
    await onVehiclesRefresh();
    const licensePlateLabel = getLicensePlateLabel(
      vehicle.registrationCountry,
      vehicle.registrationNumber
    );

    if (status === 200) {
      openSnackbar(`Usunięto pojazd ${licensePlateLabel}`, 'success');
    } else {
      openSnackbar(
        `Wystąpił błąd podczas usuwania pojazdu ${licensePlateLabel}`,
        'error'
      );
    }
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const getRoVehicleCategory = () => {
    if (vehicle.roCategory === null) {
      return '';
    }

    return vehicle.roCategory == 'V1' ? 'A' : 'B';
  };

  const getIsVehicleVisible = () => {
    if (vehicle.isVehicleVisible === null) {
      return '';
    }

    return vehicle.isVehicleVisible ? 'Tak' : 'Nie';
  };

  const renderEditButton = () => {
    if (vehicle.validated) {
      return (
        <FleetTooltip
          placement='top'
          title='Modyfikacja danych pojazdu nie jest możliwa, gdyż jego dane zostały potwierdzone przez administratora Flotomatu.'
        >
          <div>
            <Button
              size='small'
              variant='outlined'
              sx={{
                padding: 0,
                minWidth: 30,
                minHeight: 30,
                borderRadius: '3px',
              }}
              onClick={() => onVehicleEdit(vehicle)}
              disabled
            >
              <EditNoteIcon />
            </Button>
          </div>
        </FleetTooltip>
      );
    }

    return (
      <Button
        size='small'
        variant='outlined'
        sx={{
          padding: 0,
          minWidth: 30,
          minHeight: 30,
          borderRadius: '3px',
        }}
        onClick={() => onVehicleEdit(vehicle)}
      >
        <EditNoteIcon />
      </Button>
    );
  };

  const renderDeleteButton = () => {
    if (vehicle.validated) {
      return (
        <FleetTooltip
          placement='top'
          title='Usunięcie pojazdu nie jest możliwe, gdyż jego dane zostały potwierdzone przez administratora Flotomatu.'
        >
          <div>
            <Button
              size='small'
              variant='outlined'
              sx={{
                padding: 0,
                minWidth: 30,
                minHeight: 30,
                borderRadius: '3px',
              }}
              disabled
            >
              <DeleteOutlineIcon />
            </Button>
          </div>
        </FleetTooltip>
      );
    }

    if (vehicle.isDynamic) {
      return (
        <FleetTooltip
          placement='top'
          title='Usunięcie pojazdu nie jest możliwe, gdyż jego dane zostały wygenerowane na podstawie zamówień z przeszłości utworzonych przez Winiety-Online.pl.'
        >
          <div>
            <Button
              size='small'
              variant='outlined'
              sx={{
                padding: 0,
                minWidth: 30,
                minHeight: 30,
                borderRadius: '3px',
              }}
              disabled
            >
              <DeleteOutlineIcon />
            </Button>
          </div>
        </FleetTooltip>
      );
    }

    return (
      <Button
        size='small'
        variant='outlined'
        sx={{
          padding: 0,
          minWidth: 30,
          minHeight: 30,
          borderRadius: '3px',
        }}
        onClick={handleDeleteModalOpen}
      >
        <DeleteOutlineIcon />
      </Button >
    );
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <TableCell style={{ width: '30%' }}>
          <Plate
            country={vehicle.registrationCountry}
            number={vehicle.registrationNumber}
            isActive={!vehicle.isDynamic}
          />
        </TableCell>
        {isBrowser && (
          <>
            <TableCell>{vehicle.huCategory}</TableCell>
            <TableCell>{vehicle.siCategory}</TableCell>
            <TableCell>{getRoVehicleCategory()}</TableCell>
            <TableCell>{getShortVinNumber(vehicle.vinNumber)}</TableCell>
          </>
        )}
        <TableCell>{getIsVehicleVisible()}</TableCell>
        <TableCell>
          <div style={{ display: 'flex', gap: 5 }}>
            {renderEditButton()}
            {renderDeleteButton()}
          </div>
        </TableCell>
      </StyledTableRow>
      <Dialog
        keepMounted
        open={deleteModalOpen}
        TransitionComponent={Transition}
        onClose={handleDeleteModalClose}
        aria-describedby='alert-confirm-dialog-slide-description'
      >
        <DialogTitle>Usuń pojazd</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-confirm-dialog-slide-description'>
            Czy na pewno usunąć dane pojazdu{' '}
            {getLicensePlateLabel(
              vehicle.registrationCountry,
              vehicle.registrationNumber
            )}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FleetButton onClick={handleDeleteModalClose}>Anuluj</FleetButton>
          <FleetButton onClick={handleVehicleDelete}>Usuń</FleetButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
